<template>
  <div class="dictionaryManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='dictionaryManagement'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="字典管理" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
              <el-form-item label="类型">
                <el-input v-model="formInline.type" placeholder="类型" clearable></el-input>
              </el-form-item>
              <el-form-item label="字典类型">
                <el-select v-model="formInline.system" placeholder="字典类型" clearable>
                  <el-option
                    v-for="item in getDicTypeData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="dicEvent('add')" plain v-allow="'sys_dict_add'">+新增</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center">
            <el-table-column
              label="序号"
              align="center"
              width="80">
              <template slot-scope="scope">
                  {{ (formInline.current - 1) * formInline.size + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="类型"
              align="center"
              width="220">
            </el-table-column>
            <el-table-column
              prop="description"
              label="描述"
              align="center">
            </el-table-column>
            <el-table-column
              prop="system"
              label="字典类型"
              align="center"
              width="120"
              :formatter="regSystem">
            </el-table-column>
            <el-table-column
              prop="remarks"
              label="备注信息"
              align="center">
            </el-table-column>
            <el-table-column
              prop="updateTime"
              label="创建时间"
              align="center"
              width="180">
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="200"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="dicEvent('edit', scope.row, scope.$index)" v-allow="'sys_dict_edit'">编辑</el-button>
                <el-button type="text" @click="deleteEvent(scope.row)" v-allow="'sys_dict_del'">删除</el-button>
                <el-button type="text" @click="jumpEvent(scope.row)" v-allow="'sys_dict_edit'">字典项</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>

    <!-- 新增/编辑 -->
    <dialogDiy :isShow="isShowDicEdit" :dialogDiyData="dialogDicDiyData"
    @dialogEvent="dialogDicEvent">
      <div class="edit_area" slot="dialogContent">
        <el-form :model="ruleEditForm" :rules="editRules" ref="ruleEditForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="类型" prop="type">
            <el-input v-model="ruleEditForm.type" placeholder="类型" maxlength="25" :disabled="types == 'edit'" clearable></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input v-model="ruleEditForm.description" placeholder="描述" maxlength="250" clearable></el-input>
          </el-form-item>
          <el-form-item label="字典类型" prop="system">
            <el-select v-model="ruleEditForm.system" placeholder="字典类型" clearable @change="changeType">
              <el-option
                v-for="item in getDicTypeData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注信息" prop="remarks">
            <el-input v-model="ruleEditForm.remarks" placeholder="备注信息" maxlength="250" clearable></el-input>
          </el-form-item>
          <el-form-item label="创建时间" prop="createTime" v-if="types == 'edit'">
            <el-input v-model="ruleEditForm.createTime" placeholder="创建时间" :disabled="types == 'edit'" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="dialogDicEvent">取 消</el-button>
            <el-button type="primary" @click="enterEvent('ruleEditForm')">确 认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </dialogDiy>

    <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="">
        <p>{{`是否确认删除数据类型为${rowData.type}的数据项?`}}</p>
        <div class="btn_area">
          <el-button @click="batchDelete">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
  </div>
</template>

<script>
import publicChildren from '../publicChildren'
import Pagination from '../Pagination'
import dialogDiy from '../commonModule/dialogDiy'
export default {
  name: 'dictionaryManagement',
  components: {
    publicChildren,
    Pagination,
    dialogDiy,
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        type: null,
        system: null,
        current: 1,
        size: 10
      },
      getDicTypeData: [],
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      isShowDicEdit: false,
      dialogDicDiyData: {
        title: '新增',
        sizeStyle: {width: '450px',height: '400px'}
      },
      ruleEditForm: {
        '$system': null,
        '$index': null,
        createTime: null,
        updateTime: null,
        description: null,
        remarks: null,
        system: null,
        type: null,
        delFlag: null,
        id: null,
      },
      editRules: {
        type: [
          { required: true, message: '请输入类型', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ],
        system: [
          { required: true, message: '请选择字典类型', trigger: 'change' }
        ]
      },
      types: '',
      rowData: {},
      isShow: false,
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'dictionaryManagement') {
          this.getDictionEvent();
          this.getDictionaryEvent();
        }
      }
    }
  },
  methods: {
    // 获取登录日志表格数据
    getDictionaryEvent() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.queryDictionary(obj, res => {
        this.loading = false;
        if(res.code == 0){
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    // 获取字典类型
    getDictionEvent() {
      this.$request.getDiction({type: 'dict_type'}, res => {
        if(res.code == 0) {
          this.getDicTypeData = res.data;
        }
      })
    },
    regSystem(row) {
      let edu = String(row.system)
      let val = '';
      switch(edu) {
        case '0':
          val = '业务类';
          break;
        case '1':
          val = '系统类';
          break;
      }
      return val;
    },
    // 重置
    resetEvent() {
      this.formInline.type = null;
      this.formInline.system = null;
      this.formInline.current = 1;
      this.formInline.page = 10;
    },
    onSubmit() {
      this.formInline.current = 1;
      this.formInline.page = 10;
      this.getDictionaryEvent();
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.current = val.current;
      this.formInline.page = val.size;
      this.getDictionaryEvent();
    },
    dialogDicEvent(val) {
      this.isShowDicEdit = !this.isShowDicEdit;
    },
    dicEvent(types, row = {}, index) {
      this.clearEvent();
      this.dialogDicDiyData.sizeStyle.height = '400px';
      this.isShowDicEdit = true;
      this.types = types;
      this.rowData = row;
      if(types == 'edit') {
        this.dialogDicDiyData.title = '编辑';
        this.dialogDicDiyData.sizeStyle.height = '500px';
        this.ruleEditForm['$system'] = row.system == '1' ? '系统类' : '业务类';
        this.ruleEditForm['$index'] = index;
        this.ruleEditForm.createTime = row.createTime;
        this.ruleEditForm.updateTime = row.updateTime;
        this.ruleEditForm.delFlag = row.delFlag;
        this.ruleEditForm.description = row.description;
        this.ruleEditForm.remarks = row.remarks;
        this.ruleEditForm.system = row.system;
        this.ruleEditForm.type = row.type;
        this.ruleEditForm.id = row.id;
      }
    },
    clearEvent() {
      this.ruleEditForm['$system'] = null;
      this.ruleEditForm['$index'] = null;
      this.ruleEditForm.createTime = null;
      this.ruleEditForm.updateTime = null;
      this.ruleEditForm.description = null;
      this.ruleEditForm.remarks = null;
      this.ruleEditForm.system = null;
      this.ruleEditForm.type = null;
      this.ruleEditForm.delFlag = null;
      this.ruleEditForm.id = null;
    },
    changeType(val) {
      let index = this.getDicTypeData.findIndex(item=>item.value==val);
      this.ruleEditForm['$system'] = this.getDicTypeData[index].label;
    },
    enterEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if(valid){
          if(this.types == 'add') {
            this.addDicEvent();
          } else {
            this.editDicEvent();
          }
        } else {
          return false;
        }
      })
    },
    addDicEvent() {
      let ruleEditForm = this.ruleEditForm;
      this.$request.addDiction(ruleEditForm, res=>{
        if(res.code == 0) {
          this.Success('添加成功')
          this.getDictionaryEvent();
          this.isShowDicEdit = false;
        }
      })
    },
    editDicEvent() {
      let ruleEditForm = this.ruleEditForm;
      this.$request.editDiction(ruleEditForm).then( res=>{
        if(res.data.code == 0) {
          this.Success('修改成功')
          this.getDictionaryEvent();
          this.isShowDicEdit = false;
        }
      })
    },
    // 删除
    deleteEvent(row) {
      this.rowData = row;
      this.isShow = !this.isShow;
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
    },
    batchDelete() {
      this.isShow = !this.isShow;
    },
    doEvent() {
      this.$request.deleteDiction({id: this.rowData.id}).then(res => {
        if(res.data.code == 0) {
          this.Success('删除成功');
          this.getDictionaryEvent();
          this.isShow = false;
        }
      })
    },
    jumpEvent(row) {
      this.$router.push({name: 'dictionaryItemManagement', query: {
        dictId: row.id,
        type: row.type
      }})
    },
  },
}
</script>

<style lang="less">
  .dictionaryManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .edit_area {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .pubToop {
    max-width: 600px;
  }
</style>